import { useDimensions } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import { Platform } from 'react-native'

import SearchDetailHeaderContainer from '../components/screen/headers/containers/SearchDetailHeaderContainer'
import { DefaultSearchDetailHeaderProps } from '../components/screen/headers/default/DefaultSearchDetailHeader'
import HeaderWithIcons from '../components/screen/headers/HeaderWithIcons'
import useSearch from '../components/screen/hooks/useSearch'
import { Screen } from '../components/screen/Screen'
import Modal from './components/Modal'
import ModalContentWrapper from './components/ModalContentWrapper'
import { ModalProps } from './components/type'

export type ContentModalProps = {
  isVisible: boolean
  title?: string
  focusSearch?: boolean
  deferredTimeout?: number | undefined
  extraIcon?: ReactNode
  close: () => void
  onSearchChange?: (searchText: string) => void
  hideGoBackIcon?: boolean
} & Pick<ModalProps, 'onModalHide' | 'onModalShow' | 'onModalWillHide' | 'onModalWillShow'>

const SelectModal = memo(function SelectModal({
  title = '',
  focusSearch = false,
  isVisible,
  children,
  deferredTimeout = 350,
  extraIcon,
  hideGoBackIcon,
  close,
  onSearchChange,
  ...modalProps
}: PropsWithChildren<ContentModalProps>) {
  const firstRender = useRef(true)
  const { search, setSearch } = useSearch()
  const { isSmallDevice } = useDimensions()

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      onSearchChange?.(search)
    }
  }, [search])

  useEffect(() => {
    if (isVisible) return
    setSearch('')
  }, [isVisible])

  const commonSearchProps: DefaultSearchDetailHeaderProps = {
    deferredTimeout: deferredTimeout,
    enableQR: false, // TODO: QR not implemented yet
  }

  const searchEnabled = !!onSearchChange

  return (
    <Modal visible={isVisible} onClose={close} {...modalProps}>
      <ModalContentWrapper>
        <Screen forceDetailOpen={focusSearch} transparent={!isSmallDevice}>
          <Screen.Header goBack={hideGoBackIcon ? false : close} isModal={Platform.OS === 'web' ? true : !isSmallDevice}>
            <HeaderWithIcons
              title={title}
              disableFilter
              disableSearch={!searchEnabled}
              extraIcon={extraIcon}
              tabletSearchComponent={<SearchDetailHeaderContainer iconPosition="right" {...commonSearchProps} />}
            />
          </Screen.Header>
          <Screen.DetailHeader>
            <SearchDetailHeaderContainer {...commonSearchProps} />
          </Screen.DetailHeader>
          <Screen.Content>{children}</Screen.Content>
        </Screen>
      </ModalContentWrapper>
    </Modal>
  )
})

export default SelectModal
