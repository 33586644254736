const prodDemo = {
  DEMO_USERNAME: 'DEMO',
  DEMO_PASSWORD: '',
  DEMO_LICENSE_KEY: 'DEMO-EMS',
}

const devDemo = [
  {
    DEMO_USERNAME: 'Ilariaf',
    DEMO_PASSWORD: '123',
    DEMO_LICENSE_KEY: 'DEV-EMS',
  },
]

export default __DEV__ ? devDemo[0] : prodDemo
